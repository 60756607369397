









import Vue from "vue";
export default Vue.extend({
  data: function() {
    return {
      name: "",
      lastName: "",
      action: "Crear"
    };
  },
  methods: {
    onClick: function() {
      /**
       * Método que valida y emite el evento al padre
       */
      if (this.name !== "" && this.lastName !== "") {
        this.$emit("success", { name: this.name, lastName: this.lastName });
      } else {
        this.$emit("success", { msg: "No pueden haber datos vacíos" });
      }
    },
    loadValues: function(action: string,name: string ="", lastName: string="") {
      /**
       * Método para reiniciar los datos del componente
       */
      this.name = name;
      this.lastName = lastName;
      this.action = action;
    }
  }
});
