











import Vue from "vue";
export default Vue.extend({
  props: {
    name: { type: String, required: true },
    lastName: { type: String, required: true }
  }
});
