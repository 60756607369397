

































import Vue, { VueConstructor } from "vue";
import firestorage from "@/services/firebase/firestorage";
import formUser from "@/components/formUser.vue";
import cardUser from "@/components/cardUser.vue";
import modal from "@/components/modal.vue";

// Interface para el manejo de los datos del usuario
interface user {
  id: string;
  name: string;
  lastName: string;
  msg?: string;
}

//Definir el tipo de datos para usar TS
let msg: string | undefined = "";
let users: user[] = [];
let user: user;


export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      formUser: InstanceType<typeof formUser>;
    };
  }
>).extend({
  name: "App",
  components: {
    formUser,
    cardUser,
    modal
  },
  data: function() {
    return {
      msg: msg,
      userSelected: user,
      users: users,
      action: "Crear",
      isLoad: true,
      showModal: false,
      //animaciones
      animation: {
        pulse: {
          classes: "pulse",
          duration: 1000,
          iteration: "infinite"
        },
        pulseSlow: {
          classes: "pulse",
          duration: 3000,
          iteration: "infinite"
        },
        tada: {
          classes: "tada"
        },
        fade: {
          classes: "fadeIn",
          duration: 1000
        },
        introLeft: {
          classes: "slideInLeft"
        }
      }
    };
  },
  methods: {
    onSucess: function(data: user) {
      /**
       * Método para manejar el formulario
       */
      if (Object.keys(data).some((v) => v === "msg")) {
        const { msg } = data;
        this.showBanner(msg);
      } else {
        const { name, lastName } = data;
        if (this.action === "Crear") {
          firestorage
            .add({ name, lastName })
            .then((document) => {
              this.showBanner("Usuario creado");
              this.$refs.formUser.loadValues(this.action);
            })
            .catch((err) => this.showBanner(`Error al crear usuario: ${err}`));
        } else {
          this.action = "Crear";
          firestorage
            .doc(this.userSelected.id)
            .update({ name, lastName })
            .then((document) => {
              this.$refs.formUser.loadValues(this.action);
              this.showBanner("Usuario modificado");
            })
            .catch((err) =>
              this.showBanner(`Error al modificar usuario: ${err}`)
            );
        }
        this.loadUsers();
      }
    },
    onClick: function(user: user) {
      /**
       * Método para manejar el click de las cards
       */
      this.showModal = true;
      this.userSelected = user;
    },
    onUpdate: function() {
      /**
       * Método para manejar el boton update del modal
       */
      this.action = "Modificar";
      this.$refs.formUser.loadValues(
        this.action,
        this.userSelected.name,
        this.userSelected.lastName
      );
      this.showModal = false;
    },
    onDelete: function() {
      /**
       * Método para manejar el boton delete del modal
       */
      firestorage
        .doc(this.userSelected.id)
        .delete()
        .then((document) => {
          this.showBanner("Usuario Borrado con éxito!");
          this.loadUsers();
        })
        .catch((err) => this.showBanner(`Error al borrar el cliente: ${err}`));
    },
    //Metodos varios
    loadUsers: function() {
      /**
       * Carga los usuarios de firebase
       */
      this.isLoad = true;
      firestorage
        .get()
        .then((querySnapshot) => {
          this.users = [];
          querySnapshot.forEach((doc) => {
            const { name, lastName } = doc.data();
            const user: user = { id: doc.id, name, lastName };
            this.users.push(user);
          });
          this.isLoad = false;
        })
        .catch((err) => (this.msg = `Error al cargar los usuarios: ${err}`));
    },
    showBanner: function(msg: string | undefined) {
      /**
       * Método para mostrar mensajes banner
       */
      this.msg = "👉🏽 " + msg;
      setTimeout(() => (this.msg = ""), 2500);
    }
  },
  //Ciclos
  beforeMount: function() {
    this.loadUsers();
  }
});
